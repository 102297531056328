<style lang='scss'>
</style>

<template>
  <level-complete title="Молодец!" subtitle="Но это было слишком просто">
    <div class="l-text-secondary mt-6">Твоё время</div>
    <digital-time-value :value="firstLevelResult" class="mx-auto mt-1" />
  </level-complete>
</template>

<script>
import LevelComplete from './LevelComplete.vue';
import DigitalTimeValue from './UI/DigitalTimeValue.vue';
export default {
  name: "LevelCompleteFirst",
  components: {LevelComplete, DigitalTimeValue},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {
    firstLevelResult() {
      return this.$store.state.game.firstLevelResult;
    },
  },
};
</script>
