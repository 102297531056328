<style lang='postcss'>
.login-btn-top.sb-btn {
  @apply md:flex hidden mt-12;
}
.login-btn-bottom.sb-btn {
  @apply md:hidden flex mt-10;
}
</style>

<template>
  <level-block-base
    :complete="true"
    :level="2"
    title="Пароль для биткоин инвестора"
  >
    <template #title>
      {{ currentUser.username }}
    </template>
    <template #right>
      <div class="flex justify-between">
        <div>
          <div class="game-position">
            <digital-time-value :value="digitalResult" />
            <span class="ml-4" style="color: #e5dfc8"
            >{{ currentUser.position }} место</span
            >
          </div>
          <links-main />
        </div>
      </div>
    </template>
  </level-block-base>
</template>

<script>
import LevelBlockBase from "./LevelBlockBase.vue";
import LoginButton from "@/components/LoginButton.vue";
import CopyLink from "@/components/CopyLink.vue";
import LinksMain from "./LinksMain.vue";
import DigitalTimeValue from "./UI/DigitalTimeValue.vue";
import { getDigitalTime } from "../utils/helpers";
import axios from "axios";

export default {
  name: "LevelBlockResults",
  components: {
    LevelBlockBase,
    LoginButton,
    CopyLink,
    LinksMain,
    DigitalTimeValue,
  },
  data() {
    return {
      currentUser: {},
    };
  },
  props: {},
  async created() {
    const { data } = await axios.get(`/games/${this.$route.params.hash}`);
    this.currentUser = data;
  },
  mounted() {},
  methods: {},
  computed: {
    digitalResult() {
      return getDigitalTime(this.currentUser.duration);
    },
  },
};
</script>
