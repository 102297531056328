<style lang="postcss">
.results-content {
  @apply mx-auto w-full pb-36;
  margin-top: 20px;
}
@screen md {
  .results-content {
    margin-top: 6%;
    width: 758px;
  }
}
</style>

<template>
  <div class="results-content">
    <level-block-results />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    ><level-complete-first v-if="showResult" />
      <div v-else>
        <p class="game-level mt-10 md:mt-24">1 уровень</p>
        <h1 class="game-title-1 mb-16 md:mb-20">Придумай пароль</h1>
        <game-password @next="showResult = true" />
      </div>
    </transition>
  </div>
</template>

<script>
import LevelBlockResults from "@/components/LevelBlockResults.vue";
import GamePassword from "@/components/UI/GamePassword.vue";
import LevelCompleteFirst from "../../components/LevelCompleteFirst.vue";

export default {
  components: { LevelBlockResults, GamePassword, LevelCompleteFirst },
  name: "ResultsPage",
  data() {
    return {
      showResult: false,
    };
  },
};
</script>
